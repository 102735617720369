@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import "breakpoints";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    @apply text-se-gray-darker;
    @apply font-normal;
    @apply text-base;
  }

  body {
    overflow-x: hidden;
  }
}


@layer utilities {
  .input-validate {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    &.invalid-input {
      .input-validate__icon {
        &-error {
          @apply flex;
          @apply opacity-100;
          animation: show-error 2s cubic-bezier(0.33, 1, 0.68, 1) forwards;
        }
      }

      & > .input-item {
        @apply text-se-red-900;
        @apply border-opacity-50;
        @apply border-se-red-lighter;
      }

      & + div .input-item {
        @apply border-t-se-red-lighter;
      }
    }

    &.valid-input {
      .input-validate__icon {
        &-success {
          @apply flex;
        }
      }

      & > .input-item {
        @apply border-opacity-50;
        @apply border-se-blue;
      }

      & + div .input-item {
        @apply border-t-se-blue;
      }
    }

    &__icon {
      &-error {
        @apply w-5;
        @apply h-5;
        @apply absolute;
        @apply hidden;
        @apply justify-center;
        @apply items-center;
        @apply pointer-events-none;
        @apply opacity-0;
        top: 22px;
        right: 22px;

        &:before {
          @apply content-[""];
          @apply inset-0;
          @apply absolute;
          @apply rounded-full;
          @apply bg-se-bright-gray;
          transform: scale(0, 0) translate3d(0, 0, 0);
          -webkit-animation: show-error-circle 0.5s cubic-bezier(0.33, 1, 0.68, 1) 0.45s forwards;
          animation: show-error-circle 0.5s cubic-bezier(0.33, 1, 0.68, 1) 0.45s forwards;
        }

        & > .icon {
          @apply w-2.5;
          @apply relative;
          @apply fill-white;
          @apply opacity-0;
          z-index: 1;
          transform: scale(0) translateZ(0);
          -webkit-animation: show-error-inner 0.5s cubic-bezier(0.33, 1, 0.68, 1) 0.45s forwards;
          animation: show-error-inner 0.5s cubic-bezier(0.33, 1, 0.68, 1) 0.45s forwards;
        }
      }

      &-success {
        @apply w-5;
        @apply h-5;
        @apply absolute;
        @apply hidden;
        @apply justify-center;
        @apply items-center;
        @apply pointer-events-none;
        top: 22px;
        right: 22px;

        .icon {
          @apply shrink-0;
          @apply w-5;
          @apply h-5;
          fill: #10A9C3;
          transform: scale(1) translateZ(0);
          -webkit-animation: success-tick 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
          animation: success-tick 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
        }

        .lines {
          @apply block;
          @apply w-9;
          @apply h-9;
          @apply absolute;
          @apply -left-2;
          @apply -top-2;
          stroke-dasharray: 7px;
          stroke-dashoffset: 21px;
          stroke: #10A9C3;
          stroke-width: 1.1px;
          stroke-linecap: round;
          -webkit-animation: success-lines 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0.2s;
          animation: success-lines 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0.2s;
        }
      }
    }
  }
}

@keyframes show-error {
  0% {
    opacity: 1;
    transform: translate3d(0, 28px, 0);
  }
  33% {
    transform: translate3d(0, -2px, 0);
    opacity: 1;
  }
  66% {
    transform: translate3d(0, 2px, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes show-error-circle {
  33% {
    transform: scale(0.7, 1.1) translate3d(0, 0, 0);
  }
  33%,
  100% {
    @apply bg-se-red-500;
  }
  66% {
    transform: scale(1.05, 0.95) translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
    @apply bg-se-red-500;
  }
}

@keyframes show-error-inner {
  100% {
    @apply opacity-100;
    transform: scale(1, 1) translate3d(0, 0, 0);
  }
}

@keyframes success-tick {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1) translateZ(0);
  }
}

@keyframes success-lines {
  100% {
    stroke-dashoffset: 7px;
  }
}
